export default `<h4>Timtest's code of conduct</h4>
<ul style="text-align:left;">
	<li>Transport must be carried out in accordance with applicable laws and regulations in the country where the transport is carried out.</li>
	<li>When using foreign trucks for transportation in Norway, cabotage rules must be followed, such as:</li>
	<ul>
		<li>The truck must have had international cargo to  Norway. </li>
		<li>Only 3 assignments can be completed and  within a week after the car has unloaded international cargo </li>
		<li>The work to be performed under this  contract is subject to a general collective agreement under the General  Application Act. This means that all drivers at the carrier / freight  forwarder and subcontractors must at least have the wage and working  conditions that follow from "Regulations for the partial application  of collective agreements for the carriage of goods by road"</li>
	</ul>
	<li>Only 3 axle trucks with winter tires and the required number of snow chains can be used in Norway in winter conditions. </li>
	<li>Temperature logs must be sent immediately to Cermaq Norway AS. Temperature requirements for fresh fish are 0-2 degrees Celsius and -23 degrees for frozen fish. </li>
	<li>Proof of delivery must be presented immediately upon request. </li>
	<li>The carrier must have valid cargo insurance. </li>
	<li>All trailers need to be cleaned to meet hygiene specifications.</li>
	<li>Deviations during transport must be reported immediately to <a href="mailto:support@timpex.com">support@timpex.com</a>. </li>
</ul>`;